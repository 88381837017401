<template>
  <v-sheet outlined class="mt-1">
    <v-card-text class="pa-2 pl-4">
      <v-row no-gutters>
        <v-col sm="3" md="1" lg="2" class="d-flex flex-nowrap">
          {{ item.username }}
        </v-col>
        <v-col sm="3" md="2" lg="2" class="d-flex flex-nowrap">
          {{ item.name }}
        </v-col>
        <v-col sm="6" md="4" lg="3" class="d-flex flex-nowrap">
          {{ item.email }}
        </v-col>
        <v-col sm="6" md="2" lg="2">
          <v-chip :color="getColorByStatus(item)" outlined small dark>
            {{ item.status }}
          </v-chip>
        </v-col>
        <v-col sm="3" md="2" lg="2">
          <v-chip color="grey" outlined small dark>
            {{
              item.superAdmin
                ? $t("user.role.superAdmin")
                : $t("user.role.admin")
            }}
          </v-chip>
        </v-col>
        <v-col sm="3" md="1" lg="1" class="text-end">
          <v-btn x-small icon color="primary" @click="$emit('editInfo')">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn x-small icon color="primary" @click="$emit('deleteItem')">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProfileItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("user", ["getColorByStatus"]),
  },
};
</script>
