<template>
  <v-form @submit.prevent="updateItem">
    <v-card>
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between"
      >
        <span v-if="profile">Edit system user</span>
        <span v-if="!profile">Create system user</span>

        <v-btn icon color="white" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.15"
        >
          <v-progress-circular size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <v-text-field
          name="title"
          :label="attributeLabels.title"
          :error-messages="profileErrors.title"
          v-model="profileForm.title"
          type="text"
        ></v-text-field>
        <v-text-field
          name="email"
          :label="attributeLabels.email"
          :error-messages="profileErrors.email"
          v-model="profileForm.email"
          type="text"
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              name="username"
              :label="attributeLabels.username"
              :error-messages="profileErrors.username"
              v-model="profileForm.username"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="availableStatuses"
              :label="attributeLabels.status"
              :error-messages="profileErrors.status"
              v-model="profileForm.status"
            ></v-select>
          </v-col>
        </v-row>
        <v-text-field
          name="password"
          :label="attributeLabels.password"
          :error-messages="profileErrors.password"
          v-model="profileForm.password"
          type="text"
        ></v-text-field>
        <v-checkbox
          name="superAdmin"
          :label="attributeLabels.superAdmin"
          :error-messages="profileErrors.superAdmin"
          v-model="profileForm.superAdmin"
          value="1"
          false-value="0"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
        <v-btn type="submit" color="blue darken-1" text> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import _ from "lodash";
import validator from "@/mixin/validator";
import { maxLength, required, email } from "vuelidate/lib/validators";
import { PATCH_USER } from "@/store/actions/user";
import { mapGetters } from "vuex";

const defaultValues = {
  title: null,
  username: null,
  email: null,
  password: null,
  status: null,
  superAdmin: null,
};

export default {
  name: "ProfileForm",
  mixins: [validator],
  props: {
    profile: Object,
  },
  data: function () {
    return {
      dataLoading: false,
      profileForm: this.profile
        ? {
            title: this.profile.title,
            username: this.profile.username,
            email: this.profile.email,
            password: "",
            status: this.profile.status,
            superAdmin: this.profile.superAdmin ? "1" : "0",
          }
        : _.merge({}, defaultValues),
      birthdayModal: false,
      attributeLabels: {
        username: this.$t("user.attribute.username"),
        email: this.$t("user.attribute.email"),
        title: this.$t("user.attribute.title"),
        status: this.$t("user.attribute.status"),
        superAdmin: this.$t("user.attribute.superAdmin"),
        password: this.$t("user.attribute.password"),
      },
      switchers: [
        {
          name: this.$t("yes"),
          value: 1,
        },
        {
          name: this.$t("no"),
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("user", ["availableStatuses"]),
    profileErrors: function () {
      return _.merge(
        {
          title: "",
          username: "",
          email: "",
          password: "",
          status: "",
          superAdmin: "",
        },
        this.validator.errors.profileForm
      );
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        const { profileForm } = this;
        this.$store
          .dispatch("user/" + PATCH_USER, {
            user: profileForm,
            id: this.profile ? this.profile.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.profileForm = defaultValues;
                this.$emit("submitted");
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  validations: {
    profileForm: {
      title: {
        required,
        maxLength: maxLength(255),
      },
      username: {
        required,
        maxLength: maxLength(255),
      },
      email: {
        email,
        required,
        maxLength: maxLength(255),
      },
      password: {
        maxLength: maxLength(64),
      },
    },
  },
};
</script>
