<template>
  <v-form @submit.prevent="submit">
    <v-card>
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between"
      >
        {{ $t("filters") }}
        <v-btn icon color="white" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row>
          <v-col>
            <v-text-field
              name="title"
              :label="attributeLabels.title"
              v-model="search.title"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              name="email"
              :label="attributeLabels.email"
              v-model="search.email"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              name="username"
              :label="attributeLabels.username"
              v-model="search.username"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              :clearable="true"
              :items="availableStatuses"
              :label="attributeLabels.status"
              v-model="search.status"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              value="1"
              false-value="0"
              name="display"
              :label="attributeLabels.superAdmin"
              v-model="search.superAdmin"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
        <v-btn type="submit" color="blue darken-1" text> Apply filters </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { emptyFilters } from "@/store/modules/user/state";

const emptyFiltersObj = {};
Object.assign(emptyFiltersObj, emptyFilters);

export default {
  name: "ProfileSearch",
  props: {
    labels: Object,
  },
  data: function () {
    return {
      birthdayModal: false,
      datepickerMenu: false,
      search: emptyFiltersObj,
      attributeLabels: this.labels,
      statusDateRange: [],
    };
  },
  computed: {
    ...mapGetters("user", ["availableStatuses", "filters"]),
  },
  methods: {
    submit: function () {
      let filters = {};
      Object.assign(filters, this.search);
      this.$emit("applyFilters", { filters });
    },
  },
  created() {
    Object.assign(this.search, this.filters);
  },
};
</script>
