<template>
  <div class="about">
    <v-row>
      <v-col>
        <h1>{{ $t("user.listTitle") }}</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn small color="primary" @click="editItem(null)">
          {{ $t("user.create") }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between">
      <div class="pt-4 font-weight-bold">
        <v-btn text large color="primary" @click="filterDialog = true">
          {{ $t("filters") }}
          <v-icon small class="ml-2">mdi-filter-menu-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="hasAppliedFilters"
          outlined
          x-small
          color="red"
          class="ml-2"
          @click="resetFilters"
        >
          {{ $t("resetFilters") }}
        </v-btn>
      </div>
      <div class="d-flex">
        <v-select
          item-text="label"
          item-value="attribute"
          :items="attributes"
          label="Order by"
          v-model="sort.sortBy"
          style="max-width: 180px"
        ></v-select>
        <span v-if="sort.sortBy" class="mt-5 ml-2">
          <v-icon v-if="sort.desc" @click="sort.desc = false">
            mdi-sort-descending
          </v-icon>
          <v-icon v-if="!sort.desc" @click="sort.desc = true">
            mdi-sort-ascending
          </v-icon>
        </span>
      </div>
    </div>
    <div v-if="hasAppliedFilters">
      <v-chip
        close
        color="primary lighten-1"
        v-for="(filter, label) in formattedFilters"
        :key="label"
        class="mr-1 mb-2"
        @click:close="resetFilter(label)"
      >
        {{ attributeLabels[label] }}: {{ prepareAttributeValue(label, filter) }}
      </v-chip>
    </div>
    <div class="text-right">
      <span v-if="totalCount > 0" class="ml-2 body-2"
        >Showing {{ offset + 1 }}-{{ maxItem }} of {{ totalCount }} items</span
      >
    </div>
    <div>
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div v-if="!dataLoading">
        <!--    Data table     -->
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="items"
        >
          <template v-slot:header>
            <div class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0">
              <v-row class="grey--text body-2">
                <v-col cols="2" lg="2" class="align-self-end">
                  {{ $t("user.attribute.username") }}
                </v-col>
                <v-col lg="2" class="align-self-end">
                  {{ $t("user.attribute.name") }}
                </v-col>
                <v-col lg="3" class="align-self-end">
                  {{ $t("user.attribute.email") }}
                </v-col>
                <v-col lg="2" class="align-self-end">
                  {{ $t("user.attribute.status") }}
                </v-col>
                <v-col lg="2" class="align-self-end">
                  {{ $t("user.attribute.role") }}
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:default="props">
            <profile-item
              v-for="(item, i) in props.items"
              :key="i"
              :item="item"
              @editInfo="editItem(item)"
              @deleteItem="confirmItem(item)"
            ></profile-item>
          </template>
        </v-data-iterator>
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2"
            >Showing {{ offset + 1 }}-{{ maxItem }} of
            {{ totalCount }} items</span
          >
        </div>
      </div>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
    <v-dialog v-model="filterDialog" max-width="650px">
      <profile-search
        v-if="filterDialog"
        :labels="attributeLabels"
        @close="filterDialog = false"
        @applyFilters="applyFilters"
      ></profile-search>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="450px">
      <profile-form
        v-if="editDialog"
        :profile="selectedItem"
        @close="closeEditDialog"
        @submitted="
          loadItems();
          closeEditDialog();
        "
      ></profile-form>
    </v-dialog>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          Delete confirmation
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          Are you sure you want to delete {{ selectedItem.title }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItem(selectedItem)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";

import { FETCH_USERS, SET_FILTER, DELETE_USER } from "@/store/actions/user";

import { emptyFilters } from "@/store/modules/user/state";

import ProfileItem from "@/views/profile-management/list/ProfileItem";
import ProfileSearch from "@/views/profile-management/list/ProfileSearch";
import ProfileForm from "@/views/profile-management/item/ProfileForm";

export default {
  name: "ProfileList",
  components: { ProfileSearch, ProfileItem, ProfileForm },
  data() {
    return {
      selectedItem: null,
      editDialog: false,
      deleteDialog: false,
      filterDialog: false,
      dataLoading: true,
      attributeLabels: {
        id: this.$t("user.attribute.id"),
        username: this.$t("user.attribute.username"),
        email: this.$t("user.attribute.email"),
        title: this.$t("user.attribute.title"),
        status: this.$t("user.attribute.status"),
        superAdmin: this.$t("user.attribute.superAdmin"),
        createdAt: this.$t("user.attribute.createdAt"),
        updatedAt: this.$t("user.attribute.updatedAt"),
      },
      attributes: [
        {
          attribute: "id",
          label: this.$t("user.attribute.id"),
        },
        {
          attribute: "username",
          label: this.$t("user.attribute.username"),
        },
        {
          attribute: "email",
          label: this.$t("user.attribute.email"),
        },
        {
          attribute: "title",
          label: this.$t("user.attribute.title"),
        },
        {
          attribute: "status",
          label: this.$t("user.attribute.status"),
        },
        {
          attribute: "createdAt",
          label: this.$t("user.attribute.createdAt"),
        },
        {
          attribute: "updatedAt",
          label: this.$t("user.attribute.updatedAt"),
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["pagination", "filters", "sort", "emptyFilters"]),
    ...mapGetters("user", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "hasAppliedFilters",
      "formattedFilters",
    ]),
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
  },
  watch: {
    sort: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    pagination: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    filters: {
      handler: function () {
        this.$router.push({
          name: "ProfileList",
          query: this.formattedFilters,
        });
        this.loadItems();
      },
      deep: true,
    },
  },
  methods: {
    loadItems: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("user/" + FETCH_USERS)
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    resetFilters: function () {
      this.$store.commit("user/" + SET_FILTER, this.emptyFilters);
    },
    resetFilter: function (filterName) {
      let newFilters = _.merge({}, this.filters);
      newFilters[filterName] = _.get(emptyFilters, filterName, null);
      this.$store.commit("user/" + SET_FILTER, newFilters);
    },
    applyFilters: function (data) {
      this.$store.commit("user/" + SET_FILTER, data.filters);
      this.filterDialog = false;
    },
    prepareAttributeValue: function (attribute, value) {
      return value;
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    confirmItem: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.dataLoading = true;
      this.$store
        .dispatch("user/" + DELETE_USER, item)
        .then(
          () => {
            this.dataLoading = false;
            this.selectedItem = null;
            this.deleteDialog = false;
            this.loadItems();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    if (!_.isEmpty(this.$route.query)) {
      let filters = {};
      _.forIn(this.$route.query, function (value, key) {
        filters[key] = value;
      });
      this.$store.commit(
        "user/" + SET_FILTER,
        _.merge({}, this.emptyFilters, filters)
      );
    }
    this.loadItems();
  },
};
</script>
